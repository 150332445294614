<template>
<!-- <BadgeTypeDescriptions :badge-type="selectedTab" /> -->
<div class="kt-portlet kt-portlet--tabs">
    <div class="kt-portlet__head">
        <div class="kt-portlet__head-label">
            <div v-if="!isAdminLocked && !finalized" style="width: 72px">
                <span
                    class="mt-3 kt-switch kt-switch--outline kt-switch--icon"
                    :class="enableBadges ? 'kt-switch--success' : 'kt-switch--danger'"
                >
                    <label>
                        <input
                            v-model="enableBadges"
                            type="checkbox"
                        >
                        <span />
                    </label>
                </span>
            </div>
            <h3 class="kt-portlet__head-title">
                Anecdotals & Badges
                <small>
                    {{ enableBadges ? 'Enabled' : 'Disabled' }}
                </small>
            </h3>
        </div>
        <div v-if="enableBadges" class="kt-portlet__head-toolbar">
            <div class="kt-portlet__head-group mt-3">
                <router-link
                    v-if="selectedTab !== 'Anecdotal Categories'"
                    :to="{
                        name: 'EditBadge',
                        query: {
                            badgeType: selectedTab,
                        },
                        params: {
                            badgeId: 'new'
                        }
                    }"
                    class="btn btn-sm btn-clean"
                >
                    <i class="la la-plus" />
                    Add {{ selectedTab }}
                </router-link>
                <router-link
                    v-else
                    :to="{
                        name: 'EditAnecdotalCategory',
                        params: {
                            anecdotalEventCategoryId: 'new'
                        }
                    }"
                    class="btn btn-sm btn-clean"
                >
                    <i class="la la-plus" />
                    Add {{ selectedTab }}
                </router-link>
            </div>
        </div>
    </div>
    <div v-if="enableBadges && !isAdminLocked" class="kt-portlet__foot kt-portlet__foot--sm kt-align-center pt-3">
        <button
            class="btn btn-label-primary btn-pill btn-sm mr-3"
            @click="importDefaultSet"
        >
            Reset To Defaults
        </button>
        <!-- <button
            class="btn btn-label-primary btn-pill btn-sm mr-3"
            @click="deleteDefaultSet"
        >
            Delete all
        </button> -->
    </div>
    <div class="kt-portlet__body">
        <b-overlay
            :variant="'transparent'"
            center
            opacity="1"
            blur="10px"
            :show="!enableBadges"
        >
            <template #overlay>
                <p />
            </template>
            <ul class="pt-2 nav nav-tabs nav-tabs-line nav-tabs-line-brand nav-tabs-line-2x nav-tabs-bold" role="tablist">
                <li
                    v-for="tab in tabs"
                    :key="tab.name"
                    class="nav-item"
                >
                    <router-link
                        :to="{
                            name: $route.name,
                            query: {
                                badgeType: tab.name
                            }
                        }"
                        :class="tab.name == selectedTab ? 'nav-link active' : 'nav-link'"
                        role="tab"
                    >
                        <i :class="tab.icon" aria-hidden="true" />
                        {{ tab.name }}
                    </router-link>
                </li>
            </ul>
            <div class="tab-content pt-4">
                <BadgeSetupStep :setup-type="selectedTab" />
            </div>
        </b-overlay>
    </div>
    <BadgeImportModal ref="badgeImportModal" />
</div>
</template>

<script lang="ts">

import Vue from 'vue';

import Types from '../store/Types';
import * as network from '../network';
import BadgeSetupStep from '../components/BadgeSetupStep.vue';
import BadgeImportModal from '../components/BadgeImportModal.vue';
import BadgeTypeDescriptions from '../components/BadgeTypeDescriptions.vue';

export default Vue.extend({
    name: 'BadgeSetupWizard',
    components: {
        BadgeSetupStep,
        BadgeImportModal,
        // BadgeTypeDescriptions,
    },
    data() {
        return {
            key: 0,
            tabs: [
                {name: 'Anecdotal Categories', icon: 'flaticon2-layers'},
                {name: 'Administrative Badge', icon: 'flaticon2-layers-1'},
                {name: 'Academic Award', icon: 'flaticon2-writing'},
                // {name: 'Achievement Level', icon: 'flaticon2-line-chart'},
            ],
            anecdotalEventCategories: [],
            administrativeBadges: [],
            achievementBadges: [],
            academicBadges: [],
            otherBadges: [],
            saving: false,
        };
    },
    computed: {
        isAdminLocked() {
            const school = this.$store.state.database.schools
                .find((s) => s.schoolId == this.$store.state.user.school.schoolId);
            const { schoolYear } = this.$store.state.user.school;
            const { isSuperAdmin } = this.$store.state.user;
            const { subscriptions } = school;
            const subscription = subscriptions.find((s) => s.schoolYear == schoolYear );
            let isAdminLocked = false;
            if (subscription) {
                isAdminLocked = subscription.adminLocked && !isSuperAdmin;
            }
            return isAdminLocked;
        },
        finalized() {
            return this.$store.state.user.school.finalized;
        },
        enableBadges: {
            get() {
                const { finalized } = this.$store.state.user.school;
                const { anecdotalsEnabled } = this.$store.state.user.subscription;
                if (finalized || anecdotalsEnabled) {
                    return anecdotalsEnabled;
                } else {
                    const { bypassWizardBadges } = this.$store.state.wizard;
                    if (bypassWizardBadges == 'true') return true;
                    return false;
                }
            },
            set(enabled) {
                const { finalized } = this.$store.state.user.school;
                if (finalized) return;
                const value = enabled ? 'true' : '';

                this.$store.commit(Types.mutations.SKIP_SETUP_FOR_ROUTE, {
                    routeName: this.step.routeName,
                    value,
                });

                this.$store.dispatch(Types.actions.CALCULATE_SETUP_STEPS);
            },
        },
        user() {
            return this.$store.state.user;
        },
        isSetupWizard() {
            return this.$route.name == 'SetupWizardRoute';
        },
        step() {
            return this.$store.state.wizard.steps.find((step) => step.routeName === 'setup-anecdotals');
        },
        selectedTab() {
            return this.$route.query.badgeType || 'Anecdotal Categories';
        },
        reloadKey() {
            return this.$store.state.database.reloadKey;
        },
        badges() {
            return this.$store.state.database.badges;
        },
    },
    mounted() {
        const { isSetupWizard, isAdminLocked, enableBadges } = this;
        if (isSetupWizard && isAdminLocked && !enableBadges) {
            return this.$router.push({ name: 'SetupWizard' });
        }

        const { dispatch, commit } = this.$store;
        // this.$root.$on('bv::modal::hide', (bvEvent, modalId) => {
        //     dispatch(Types.actions.REFRESH_TABLE_BY_NAME, {
        //         tableName: 'badges',
        //         callback: function() {
        //             commit(Types.mutations.BUMP_RELOAD_KEY);
        //         }
        //     });
        // });
    },
    methods: {
        importDefaultSet() {
            this.$refs.badgeImportModal.open();
        },
        createNewCategory() {
            this.$router.push({ name: 'EditAnecdotalCategory', params: { anecdotalEventCategoryId: 'new' } });
        },
        editCategory(category) {
            this.$router.push({ name: 'EditAnecdotalCategory', params: { anecdotalEventCategoryId: category.anecdotalEventCategoryId } });
        },
    },
});

</script>

<style scoped src='../css/colors.scss' lang="scss" />

<style scoped>
span.kt-point-badge {
    font-size: 1.3rem;
    font-weight: 600;
    margin-left: -12px;
    white-space: nowrap;
}
.table tr td {
    vertical-align: middle;
}
td.row_actions {
    min-width: 100px;
    white-space: nowrap;
}
td.row_desc {
    min-width: 200px;
}
td.row_subs {
    min-width: 200px;
}

.table thead tr td {
    padding-bottom: 20px;
}

a.anchor-link:hover {
    text-decoration: underline !important;
    text-underline-offset: 4px;
}
</style>
