var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "kt-portlet kt-portlet--tabs" },
    [
      _c("div", { staticClass: "kt-portlet__head" }, [
        _c("div", { staticClass: "kt-portlet__head-label" }, [
          !_vm.isAdminLocked && !_vm.finalized
            ? _c("div", { staticStyle: { width: "72px" } }, [
                _c(
                  "span",
                  {
                    staticClass:
                      "mt-3 kt-switch kt-switch--outline kt-switch--icon",
                    class: _vm.enableBadges
                      ? "kt-switch--success"
                      : "kt-switch--danger",
                  },
                  [
                    _c("label", [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.enableBadges,
                            expression: "enableBadges",
                          },
                        ],
                        attrs: { type: "checkbox" },
                        domProps: {
                          checked: Array.isArray(_vm.enableBadges)
                            ? _vm._i(_vm.enableBadges, null) > -1
                            : _vm.enableBadges,
                        },
                        on: {
                          change: function ($event) {
                            var $$a = _vm.enableBadges,
                              $$el = $event.target,
                              $$c = $$el.checked ? true : false
                            if (Array.isArray($$a)) {
                              var $$v = null,
                                $$i = _vm._i($$a, $$v)
                              if ($$el.checked) {
                                $$i < 0 &&
                                  (_vm.enableBadges = $$a.concat([$$v]))
                              } else {
                                $$i > -1 &&
                                  (_vm.enableBadges = $$a
                                    .slice(0, $$i)
                                    .concat($$a.slice($$i + 1)))
                              }
                            } else {
                              _vm.enableBadges = $$c
                            }
                          },
                        },
                      }),
                      _c("span"),
                    ]),
                  ]
                ),
              ])
            : _vm._e(),
          _c("h3", { staticClass: "kt-portlet__head-title" }, [
            _vm._v(" Anecdotals & Badges "),
            _c("small", [
              _vm._v(
                " " + _vm._s(_vm.enableBadges ? "Enabled" : "Disabled") + " "
              ),
            ]),
          ]),
        ]),
        _vm.enableBadges
          ? _c("div", { staticClass: "kt-portlet__head-toolbar" }, [
              _c(
                "div",
                { staticClass: "kt-portlet__head-group mt-3" },
                [
                  _vm.selectedTab !== "Anecdotal Categories"
                    ? _c(
                        "router-link",
                        {
                          staticClass: "btn btn-sm btn-clean",
                          attrs: {
                            to: {
                              name: "EditBadge",
                              query: {
                                badgeType: _vm.selectedTab,
                              },
                              params: {
                                badgeId: "new",
                              },
                            },
                          },
                        },
                        [
                          _c("i", { staticClass: "la la-plus" }),
                          _vm._v(" Add " + _vm._s(_vm.selectedTab) + " "),
                        ]
                      )
                    : _c(
                        "router-link",
                        {
                          staticClass: "btn btn-sm btn-clean",
                          attrs: {
                            to: {
                              name: "EditAnecdotalCategory",
                              params: {
                                anecdotalEventCategoryId: "new",
                              },
                            },
                          },
                        },
                        [
                          _c("i", { staticClass: "la la-plus" }),
                          _vm._v(" Add " + _vm._s(_vm.selectedTab) + " "),
                        ]
                      ),
                ],
                1
              ),
            ])
          : _vm._e(),
      ]),
      _vm.enableBadges && !_vm.isAdminLocked
        ? _c(
            "div",
            {
              staticClass:
                "kt-portlet__foot kt-portlet__foot--sm kt-align-center pt-3",
            },
            [
              _c(
                "button",
                {
                  staticClass: "btn btn-label-primary btn-pill btn-sm mr-3",
                  on: { click: _vm.importDefaultSet },
                },
                [_vm._v(" Reset To Defaults ")]
              ),
            ]
          )
        : _vm._e(),
      _c(
        "div",
        { staticClass: "kt-portlet__body" },
        [
          _c(
            "b-overlay",
            {
              attrs: {
                variant: "transparent",
                center: "",
                opacity: "1",
                blur: "10px",
                show: !_vm.enableBadges,
              },
              scopedSlots: _vm._u([
                {
                  key: "overlay",
                  fn: function () {
                    return [_c("p")]
                  },
                  proxy: true,
                },
              ]),
            },
            [
              _c(
                "ul",
                {
                  staticClass:
                    "pt-2 nav nav-tabs nav-tabs-line nav-tabs-line-brand nav-tabs-line-2x nav-tabs-bold",
                  attrs: { role: "tablist" },
                },
                _vm._l(_vm.tabs, function (tab) {
                  return _c(
                    "li",
                    { key: tab.name, staticClass: "nav-item" },
                    [
                      _c(
                        "router-link",
                        {
                          class:
                            tab.name == _vm.selectedTab
                              ? "nav-link active"
                              : "nav-link",
                          attrs: {
                            to: {
                              name: _vm.$route.name,
                              query: {
                                badgeType: tab.name,
                              },
                            },
                            role: "tab",
                          },
                        },
                        [
                          _c("i", {
                            class: tab.icon,
                            attrs: { "aria-hidden": "true" },
                          }),
                          _vm._v(" " + _vm._s(tab.name) + " "),
                        ]
                      ),
                    ],
                    1
                  )
                }),
                0
              ),
              _c(
                "div",
                { staticClass: "tab-content pt-4" },
                [
                  _c("BadgeSetupStep", {
                    attrs: { "setup-type": _vm.selectedTab },
                  }),
                ],
                1
              ),
            ]
          ),
        ],
        1
      ),
      _c("BadgeImportModal", { ref: "badgeImportModal" }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }