<template>
<div class="table-responsive pb-3">
    <table class="table table-striped">
        <tbody v-if="setupType !== 'Anecdotal Categories'">
            <tr
                v-for="(badge, idx) in items"
                :key="`badge_${badge.badgeId}`"
            >
                <td class="row_desc ">
                    <div class="media">
                        <span
                            v-if="badge.badgeIcon"
                            style="font-size: 2rem;"
                            class="mr-3"
                        >
                            {{ badge.badgeIcon }}
                        </span>
                        <div class="media-body align-self-center">
                            <router-link
                                :to="{
                                    name: 'EditBadge',
                                    params: {
                                        badgeId: badge.badgeId
                                    },
                                }"
                                :class="`anchor-link kt-font-bold font ${badge.badgeColor}`"
                            >
                                <span v-if="badge.badgeType == 'Achievement Level'">{{ idx+1 }}. </span>
                                <span>{{ badge.badgeTitle }}</span>
                            </router-link>
                            <div class="text-muted">
                                {{ badge.badgeDescription }}
                            </div>
                        </div>
                    </div>
                </td>
                <td class="row_actions text-center">
                    <button
                        :v-b-tooltip.hover="'Delete Badge'"
                        type="button"
                        class="btn btn-clean btn-clean-danger btn-icon btn-sm"
                        @click.stop.prevent="deleteBadge(badge)"
                    >
                        <i class="la la-trash" />
                    </button>

                    <button
                        v-if="badge.badgeType !== 'Academic Award'"
                        :v-b-tooltip.hover="'Move Up'"
                        type="button"
                        class="btn btn-clean btn-clean-primary btn-icon btn-sm"
                        @click.stop.prevent="moveLevelUp(idx)"
                    >
                        <i class="la la-angle-up" />
                    </button>
                    <button
                        v-if="badge.badgeType !== 'Academic Award'"
                        :v-b-tooltip.hover="'Move Down'"
                        type="button"
                        class="btn btn-clean btn-clean-primary btn-icon btn-sm"
                        @click.stop.prevent="moveLevelDown(idx)"
                    >
                        <i class="la la-angle-down" />
                    </button>
                </td>
            </tr>
        </tbody>
        <tbody v-else>
            <tr
                v-for="(category, idx) in items"
                :key="`anec_${category.anecdotalEventCategoryId}`"
            >
                <td class="row_desc ">
                    <div class="media">
                        <span
                            v-if="category.categoryIcon"
                            style="font-size: 2rem;"
                            class="mr-3"
                        >
                            {{ category.categoryIcon }}
                        </span>
                        <div class="media-body align-self-center">
                            <router-link
                                :to="{
                                    name: 'EditAnecdotalCategory',
                                    params: {
                                        anecdotalEventCategoryId: category.anecdotalEventCategoryId
                                    },
                                }"
                                :class="`anchor-link font ${category.categoryColor}`"
                            >
                                {{ category.anecdotalEventCategoryTitle }}
                            </router-link>
                        </div>
                    </div>
                </td>
                <td class="row_worth text-center">
                    <div>
                        <span
                            :title="formatNumber(category.pointValue)"
                            class="kt-point-badge pull-right"
                            :class="{
                                'kt-font-success': category.pointValue > 0,
                                'kt-font-danger': category.pointValue < 0,
                            }"
                        >
                            {{ category.pointValue > 0 ? '+' : '' }}
                            {{ abbrevNumber(category.pointValue) }}
                        </span>
                    </div>
                </td>
                <td class="row_delete text-center">
                    <button
                        :v-b-tooltip.hover="'Delete Category'"
                        type="button"
                        class="btn btn-clean btn-clean-danger btn-icon btn-sm"
                        @click.stop.prevent="deleteCategory(category)"
                    >
                        <i class="la la-trash" />
                    </button>
                </td>
            </tr>
        </tbody>
    </table>
</div>
</template>

<script lang="ts">

import Vue from 'vue';
import Types from '../store/Types';
import * as network from '../network';

export default Vue.extend({
    name: 'BadgeSetupStep',
    props: {
        setupType: {
            type: String,
            default: 'Anecdotal Categories', // "Administrative Badge", "Academic Award", "Achievement Level"
        },
    },
    data() {
        return {
            administrativeBadges: [],
            achievementBadges: [],
            academicBadges: [],
            anecdotalEventCategories: [],
        };
    },
    computed: {
        user() {
            return this.$store.state.user;
        },
        reloadKey() {
            return this.$store.state.database.reloadKey;
        },
        badges() {
            return this.$store.state.database.badges;
        },
        items() {
            if (this.setupType === 'Administrative Badge') return this.administrativeBadges;
            if (this.setupType === 'Academic Award') return this.academicBadges;
            if (this.setupType === 'Achievement Level') return this.achievementBadges;
            if (this.setupType === 'Anecdotal Categories') return this.anecdotalEventCategories;
            return [];
        },
    },
    mounted() {
        this.populate();
    },
    methods: {
        deleteCategory(anecdotalEventCategory) {
            const v = this;
            const { schoolId, schoolTermId } = v.user.school;
            const { anecdotalEventCategoryId } = anecdotalEventCategory;
            network.anecdotals.deleteCategory({ url: { schoolId, schoolTermId, anecdotalEventCategoryId } }, (err) => {
                if (err) return v.showError(err);
                v.$store.commit(Types.mutations.DELETE_ANECDOTAL_EVENT_CATEGORY, { anecdotalEventCategoryId });
                v.$store.commit(Types.mutations.BUMP_RELOAD_KEY);
            });
        },
        populate() {

            const userId = this.$store.state.user.mappedUserId || this.$store.state.user.userId;

            const badges = [...this.$store.state.database.badges].filter(function(badge) {
                const { badgeManagers } = badge;
                if (badge.deleted) return false;
                if (badgeManagers.length === 0) return true;
                const isManager = badgeManagers.some(function(bm) {
                    return bm.userId == userId;
                });
                return isManager;
            });

            const achievementBadges = [...badges.filter((badge) => badge.badgeType == 'Achievement Level' && !badge.deleted)];
            achievementBadges.sort((a, b) => a.badgeRank - b.badgeRank);
            this.achievementBadges = achievementBadges;

            const administrativeBadges = [...badges.filter((badge) => badge.badgeType == 'Administrative Badge' && !badge.deleted)];
            administrativeBadges.sort((a, b) => a.badgeRank - b.badgeRank);
            this.administrativeBadges = administrativeBadges;

            const academicBadges = [...badges.filter((badge) => badge.badgeType == 'Academic Award' && !badge.deleted)];
            academicBadges.sort((a, b) => a.badgeTitle > b.badgeTitle ? 1 : -1); // lowest to highest
            this.academicBadges = academicBadges;

            const anecdotalEventCategories = [...this.$store.state.database.anecdotalEventCategories].filter((category) => !category.deleted);
            this.anecdotalEventCategories = anecdotalEventCategories;

            this.key += 1;
        },
        moveLevelUp(index) {
            const newIndex = index - 1;
            const { achievementBadges } = this;
            if (newIndex < 0) return;
            arrayMove(achievementBadges, index, newIndex);
            this.saveRanks();
        },
        moveLevelDown(index) {
            const newIndex = index + 1;
            const { achievementBadges } = this;
            if (newIndex >= achievementBadges.length) return;
            arrayMove(achievementBadges, index, newIndex);
            this.saveRanks();
        },
        moveBadgeUp(index) {
            const newIndex = index - 1;
            const { otherBadges } = this;
            if (newIndex < 0) return;
            arrayMove(otherBadges, index, newIndex);
            this.saveRanks();
        },
        moveBadgeDown(index) {
            const newIndex = index + 1;
            const { otherBadges } = this;
            if (newIndex >= otherBadges.length) return;
            arrayMove(otherBadges, index, newIndex);
            this.saveRanks();
        },
        saveRanks() {
            const { showError, $store } = this;
            const badges = [...this.achievementBadges, ...this.otherBadges];

            const params = {
                url: {
                    schoolId: this.user.school.schoolId,
                    schoolTermId: this.user.school.schoolTermId,
                },
                body: {
                    badges: badges.map((badge, idx) => ({ badgeId: badge.badgeId, badgeRank: idx + 1 })),
                },
            };
            network.badges.editRanks(params, (err) => {
                if (err) return showError(err);
                badges.forEach((badge) => {
                    $store.commit(Types.mutations.EDIT_BADGE, { badge });
                });
            });
        },
        createNewCategory() {
            this.$router.push({ name: 'EditAnecdotalCategory', params: { anecdotalEventCategoryId: 'new' } });
        },
        editCategory(category) {
            this.$router.push({ name: 'EditAnecdotalCategory', params: { anecdotalEventCategoryId: category.anecdotalEventCategoryId } });
        },
        deleteBadge(b) {
            const badge = { ...b };
            const { $store, showError, populate } = this;
            const { schoolId, schoolTermId } = this.user.school;
            const { badgeId } = badge;
            if (!window.confirm('Are you sure you want to delete this badge?')) return;
            network.badges.deleteBadge({ url: { schoolId, schoolTermId, badgeId } }, (err) => {
                if (err) return showError(err);
                badge.deleted = true;
                $store.commit(Types.mutations.EDIT_BADGE, { badge });
                populate();
            });
        },
    },
});

function arrayMove(arr, fromIndex, toIndex) {
    const element = arr[fromIndex];
    arr.splice(fromIndex, 1);
    arr.splice(toIndex, 0, element);
}
</script>

<style scoped src='../css/colors.scss' lang="scss" />

<style scoped>
span.kt-point-badge {
    font-size: 1.3rem;
    font-weight: 600;
    margin-left: -12px;
    white-space: nowrap;
}
.table tr td {
    vertical-align: middle;
}
td.row_actions {
    min-width: 100px;
    white-space: nowrap;
}
td.row_desc {
    min-width: 200px;
}
td.row_subs {
    min-width: 200px;
}

.table thead tr td {
    padding-bottom: 20px;
}

a.anchor-link:hover {
    text-decoration: underline !important;
    text-underline-offset: 4px;
}
</style>
