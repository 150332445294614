var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", { staticClass: "table-responsive pb-3" }, [
    _c("table", { staticClass: "table table-striped" }, [
      _vm.setupType !== "Anecdotal Categories"
        ? _c(
            "tbody",
            _vm._l(_vm.items, function (badge, idx) {
              return _c("tr", { key: `badge_${badge.badgeId}` }, [
                _c("td", { staticClass: "row_desc" }, [
                  _c("div", { staticClass: "media" }, [
                    badge.badgeIcon
                      ? _c(
                          "span",
                          {
                            staticClass: "mr-3",
                            staticStyle: { "font-size": "2rem" },
                          },
                          [_vm._v(" " + _vm._s(badge.badgeIcon) + " ")]
                        )
                      : _vm._e(),
                    _c(
                      "div",
                      { staticClass: "media-body align-self-center" },
                      [
                        _c(
                          "router-link",
                          {
                            class: `anchor-link kt-font-bold font ${badge.badgeColor}`,
                            attrs: {
                              to: {
                                name: "EditBadge",
                                params: {
                                  badgeId: badge.badgeId,
                                },
                              },
                            },
                          },
                          [
                            badge.badgeType == "Achievement Level"
                              ? _c("span", [_vm._v(_vm._s(idx + 1) + ". ")])
                              : _vm._e(),
                            _c("span", [_vm._v(_vm._s(badge.badgeTitle))]),
                          ]
                        ),
                        _c("div", { staticClass: "text-muted" }, [
                          _vm._v(" " + _vm._s(badge.badgeDescription) + " "),
                        ]),
                      ],
                      1
                    ),
                  ]),
                ]),
                _c("td", { staticClass: "row_actions text-center" }, [
                  _c(
                    "button",
                    {
                      staticClass:
                        "btn btn-clean btn-clean-danger btn-icon btn-sm",
                      attrs: { "v-b-tooltip": "Delete Badge", type: "button" },
                      on: {
                        click: function ($event) {
                          $event.stopPropagation()
                          $event.preventDefault()
                          return _vm.deleteBadge(badge)
                        },
                      },
                    },
                    [_c("i", { staticClass: "la la-trash" })]
                  ),
                  badge.badgeType !== "Academic Award"
                    ? _c(
                        "button",
                        {
                          staticClass:
                            "btn btn-clean btn-clean-primary btn-icon btn-sm",
                          attrs: { "v-b-tooltip": "Move Up", type: "button" },
                          on: {
                            click: function ($event) {
                              $event.stopPropagation()
                              $event.preventDefault()
                              return _vm.moveLevelUp(idx)
                            },
                          },
                        },
                        [_c("i", { staticClass: "la la-angle-up" })]
                      )
                    : _vm._e(),
                  badge.badgeType !== "Academic Award"
                    ? _c(
                        "button",
                        {
                          staticClass:
                            "btn btn-clean btn-clean-primary btn-icon btn-sm",
                          attrs: { "v-b-tooltip": "Move Down", type: "button" },
                          on: {
                            click: function ($event) {
                              $event.stopPropagation()
                              $event.preventDefault()
                              return _vm.moveLevelDown(idx)
                            },
                          },
                        },
                        [_c("i", { staticClass: "la la-angle-down" })]
                      )
                    : _vm._e(),
                ]),
              ])
            }),
            0
          )
        : _c(
            "tbody",
            _vm._l(_vm.items, function (category, idx) {
              return _c(
                "tr",
                { key: `anec_${category.anecdotalEventCategoryId}` },
                [
                  _c("td", { staticClass: "row_desc" }, [
                    _c("div", { staticClass: "media" }, [
                      category.categoryIcon
                        ? _c(
                            "span",
                            {
                              staticClass: "mr-3",
                              staticStyle: { "font-size": "2rem" },
                            },
                            [_vm._v(" " + _vm._s(category.categoryIcon) + " ")]
                          )
                        : _vm._e(),
                      _c(
                        "div",
                        { staticClass: "media-body align-self-center" },
                        [
                          _c(
                            "router-link",
                            {
                              class: `anchor-link font ${category.categoryColor}`,
                              attrs: {
                                to: {
                                  name: "EditAnecdotalCategory",
                                  params: {
                                    anecdotalEventCategoryId:
                                      category.anecdotalEventCategoryId,
                                  },
                                },
                              },
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(category.anecdotalEventCategoryTitle) +
                                  " "
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                    ]),
                  ]),
                  _c("td", { staticClass: "row_worth text-center" }, [
                    _c("div", [
                      _c(
                        "span",
                        {
                          staticClass: "kt-point-badge pull-right",
                          class: {
                            "kt-font-success": category.pointValue > 0,
                            "kt-font-danger": category.pointValue < 0,
                          },
                          attrs: {
                            title: _vm.formatNumber(category.pointValue),
                          },
                        },
                        [
                          _vm._v(
                            " " +
                              _vm._s(category.pointValue > 0 ? "+" : "") +
                              " " +
                              _vm._s(_vm.abbrevNumber(category.pointValue)) +
                              " "
                          ),
                        ]
                      ),
                    ]),
                  ]),
                  _c("td", { staticClass: "row_delete text-center" }, [
                    _c(
                      "button",
                      {
                        staticClass:
                          "btn btn-clean btn-clean-danger btn-icon btn-sm",
                        attrs: {
                          "v-b-tooltip": "Delete Category",
                          type: "button",
                        },
                        on: {
                          click: function ($event) {
                            $event.stopPropagation()
                            $event.preventDefault()
                            return _vm.deleteCategory(category)
                          },
                        },
                      },
                      [_c("i", { staticClass: "la la-trash" })]
                    ),
                  ]),
                ]
              )
            }),
            0
          ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }